<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>{{ $t("dns.DnsManagement") }}</CCardHeader>

          <CCardBody v-if="dnsFrameLink">
          <a :href="dnsFrameLink" ref="link" target="_blank">{{ $t("dns.DnsFrameLinkInstruction") }}</a>
            <p v-if="is_safari">
              {{ $t('SafariInstruction') }}</br>
              {{ $t('SafariFirstChoice') }}</br>
              {{ $t('SafariSecondChoice') }}</br>
              {{ $t('SafariThirdChoice') }} 
            </p>
            <CEmbed
              tag="p"
              aspect="16by9"
              :src="dnsFrameLink"
              frameborder="0"
              :key="dnsFrameLink"
            />
          </CCardBody>
          <CCardBody v-else>
            <h2>{{ $t('dns.DnsPermissionInstruction') }}</h2>
            <p>{{ $t('dns.DnsSupportInstruction') }}</p>
            <p>{{ $t('dns.DnsInstruction') }}</p>
          </CCardBody>

          <!-- <CCardBody>
              List Domains

              Tab1 : Records
              www => cname => cname.rq0.net
              * => cname => cname.rq0.net
              @ => flattening => cname.rq0.net
              m => a => 1.2.3.4

              Tab2 : Certs
              apply new certs
              download certs
              push certs to CDN
          </CCardBody> -->
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>{{ $t('dns.DnsInstructionForUse') }}</CCardHeader>
          <CCardBody>
            <h2>{{ $t('dns.DnsInstructionNS') }}{{ hello }}</h2>
            <ul>
              <li>dns3.5vp.net</li>
              <li>dns4.5vp.net</li>
              <li>dns7.5vp.net</li>
              <li>dns8.5vp.net</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
import {EventBus, Events} from "@/plugins/event-bus.js";

export default {
  name: "DnsList",
  components: {
  },
  data() {
    return {
      dnsFrameLink: null,
      hello: ""
    }
  },
  props: {
  },
  computed: {
    is_safari() {
      var is_safari = navigator.userAgent.indexOf("Safari") > -1;
      // Chrome has Safari in the user agent so we need to filter (https://stackoverflow.com/a/7768006/1502448)
      var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
      if ((is_chrome) && (is_safari)) {is_safari = false;}
      return is_safari;
    }
  },

  mounted() {
    this.loadDnsFrame();
    this.loadTest();
  },
  beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
  },
  methods: {
    loadDnsFrame() {
      return axios
        .get('dns/panel')
        .then(response => {
          this.dnsFrameLink = response.data.redirect_to;
          this.$nextTick(() => {
            if (this.is_safari) { 
              this.$refs.link.click();
            }
          }) 
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    loadTest() {
      return axios
        .get('cert/issue/hello/')
        .then(response => {
          this.hello = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
  }
};
</script>
